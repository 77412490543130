.service-row{
  padding: calc(1% + 10px) 25px calc(6% + 10px);
  color: black;
  /* height: 400px; */
  /* background: rgb(255, 255, 255); */
  /* display: flex;
  flex-wrap: wrap; */

  /* width:100vw; */
  /* object-fit: cover;
  overflow: hidden;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat; */
}

.service-text{
  font-family: MontNor;
  padding-top: 5%;
  font-size: 1rem;
}

.service-text > p, .service-text > ul{
  font-family: MontNor;
  font-size: 0.85rem;
  line-height: 1.2rem;
  margin-bottom: 0.5rem;
}

.service-text > ul > li{
  list-style-type: initial;
  list-style-position: outside;
  /* text-indent: -1.3em; */
  margin-left: 1.3em;
}

.service-text > b{
  font-family: MontserratB;
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: 0.5rem;
}



.bold-service-text{
  font-family: MontserratB;
  /* color: rgb(255, 255, 255); */
  padding-top: 10px;
  padding-bottom: 6%;
  font-size: 1.2rem;
  line-height: 1.5rem;
  /* margin-bottom: 0.9rem; */
}


@media only screen and (max-width: 600px) {

  .trial, .second-trial, .service-text, .bold-service-text{
    font-family: MontNor;
    font-size: 0.8rem;
    line-height: 16px;
    padding-top: 0;
    text-align: left;
  }

  .bold-service-text{
    font-family: MontserratB;
    padding-top: 6%;
    margin-bottom: 0.4rem;
    text-align: left;
  }

  .service-text{
    font-family: MontNor;
    /* color: #FFFFFF; */
    padding-top: 2%;
    margin-bottom: 0.5rem;
  }

  .service-text > p, .service-text > ul{
    font-family: MontNor;
    font-size: 0.7rem;
    line-height: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .service-row{
    padding: 30px 20px;
  }

}
