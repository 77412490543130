nav {
  background-color: #264293;
  /* background-color: transparent; */
  height: 55px;
  z-index: 111;
  opacity: 0.95;
}

.navbar{
  position: fixed;
  top: 0;
  width: 100%;
}

.nav {
  text-decoration: none;
  background-color: white;
}

.nav-text >li > a {
  text-decoration: none;
  color: rgb(255, 255, 255) !important;
}

.nav-text >li > a:hover {
  text-decoration: none;
  color: #ABDCFC !important;
}

.nav-text >li > a:active {
  text-decoration: none;
  font-family: MontserratB;
  color: rgb(255, 255, 255) !important;
}

img.logo {
  width: 100px !important;
  float: left;
  /* margin-right: 50px; */
}

.header-title {
    font-family: Electrolize;
    font-size: 1.4rem;
    line-height: 39px;
    color: #ffffff;
    vertical-align: middle;
    height: 100%;
    text-align: left;
    /* To center text vertically */
    display: table-cell; vertical-align: middle;
    /* float: right; */
    /* margin-left: 600px; */
    /* margin-top: -22px; */
    /* bottom : 2px; */
}

.header-subtitle {
  font-family: Electrolize;
  font-size: 1.0rem;
  color: #ffffff;
  vertical-align: middle;
  height: 100%;
  text-align: left;
  /* To center text vertically */
  display: table-cell; vertical-align: middle;
  /* float: right; */
  /* margin-left: 600px; */
  /* margin-top: -22px; */
  /* bottom : 2px; */
}

.union-faded {
  font-weight: 400 !important;
  color: #f0f0f0 !important;
}

.menu {
  font-size: 16px;
  color: #ffffff;
  display: block;
  width: 20%;
  z-index: 100;
  position: absolute;
}

#searchInput {
  background-color: transparent;
  border: 1px solid rgb(151, 151, 151) !important;
  color: #fff;
  border-radius: 20px;
  padding: 4px 10px;
  width: 100%;
}

input::placeholder {
  color: #ffffff;
}

.drop-down-menu {
  list-style: none;
  width: 60%;
  background-color: #2d9cdb;
  display: inline-block;
  margin: 0;
  height: 300px;
  position: relative;
}


/* .searchReady:focus {
  width: 700px;
  transition: 2s;
} */

/* ul li {
  display: block;
  z-index: 1000 !important;
} */

.hamburger {
  color: #ffffff !important;
  font-size: 25px;
  margin-top: 3px;
}

.headerButton{
  /* background-color: #3c424e !important; */
  background-color: tomato !important;
  color: rgb(255, 255, 255) !important;
  /* color: rgb(184, 234, 253) !important; */
  font-size: 10px;
  float: right;
  border-radius: 16px;
}

.count{
  position: absolute;
  font-size: 10px;
  padding: 1.5px 6px; /* pixel unit */
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  min-width: 12px; /* pixel unit */
  top: 4px;
  right: 3px;
  background-color: tomato !important;
  color: rgb(255, 255, 255) !important;
}

.wrapper{
  color: rgb(228, 228, 228) !important;
  font-size: 25px !important;
  float: right;
  padding: 0;
  background-color: transparent;
  border: none;
}

.wrapper:hover{
  color: rgb(177, 177, 177) !important;
  font-size: 25px !important;
  float: right;
  padding: 0;
  background-color: transparent;
  border: none;
}


.upload-btn{
  background-color: #ffffff;
  font-size: 12px;
}

/* .menu-handles {
  width: 100%;
  display: table-cell; vertical-align: middle;
} */

.list-item {
  /* color: #ffffff !important; */
  color: #ABDCFC !important;
  line-height: 50px;
  font-size: 16px !important;
}

.nav-text {
  /* color: #ffffff !important; */
  color: #ABDCFC !important;
  /* display: flex; */
  /* font-size: 25px;
  margin-top: 3px; */
}

.nav-text > li {
  color: #f7f7f7 !important;
  /* display: inline-block; */
  list-style-type: none;
  padding-left: 40px;
  text-transform: uppercase;
  float: right;
  font-size: 10px;
}


.search {
  /* position: absolute; */
  font-size: 20px !important;
  color: #ffffff !important;
  margin-top: 5px;
}

.search:focus {
  font-size: 20px !important;
  color: #ffffff !important;
  margin-top: 1px;
  margin-bottom: 0px !important;
}
 
.search:active {
  font-size: 20px !important;
  color: #ffffff !important;
  margin-top: 1px !important;
  margin-bottom: -1px !important;
}

/* .search-items {
  position: absolute;
  margin-left: 100px;
} */

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .navbar {
    display: flex;
    justify-content: flex-start;
  }

  .menu-handles {
    margin-left: 0px !important;
    border-right: 0px !important;
  }

  img.logo {
    margin-left: 0px !important;
  }
  

  .header-title {
    display: none !important;
  }


  /* .form-inline {
    margin-left: 80px;
  } */

  .drop-down-menu {
    list-style: none;
    width: 150px;
    background-color: #2d9cdb;
    display: inline-block;
    margin: 0;
    height: 300px;
    position: relative;
  }

  /* .search-items {
    position: absolute;
    margin-left: 50px;
  } */

  /* .searchReady:focus {
    width: 150px;
    transition: 3s;
  } */
  
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .logo {
    float: left;
    width: 50px !important;
  }

  .header-title {
    display:none;
  }

  .nav-text > li {
    /* color: #f7f7f7 !important; */
    display: inline-block;
    padding-left: 5px;
    text-transform: uppercase;
    font-size: 4px;
  }

}


@media only screen and (max-width: 600px) {
  /* #searchInput {
    border: none;
    outline: none;
    margin-left: 5px;
    border-radius: 20px;
    padding: 4px 10px;
    width: inherit;
  } */


  img.logo {
    margin-left: 0px !important;
    width: 60px !important;
  }

  .nav-text > li {
    /* color: #f7f7f7 !important; */
    display: inline-block;
    padding-left: 10px;
    text-transform: uppercase;
    font-size: 6.5px;
  }
  
}
