body {
  /* background: #24283009; */
  font-size: 12px;
  color: #333333;
  max-height: 100vh;
  position: relative;
  /* padding-top: 55px;  */
  /*This can be removed to make the hero start from the very top, and maybe nav floats ontop*/
  padding-bottom: 50px;
}

.rightJust{
  float: right;
  /* line-height: 32px; */
}

.imageLeft{
  /* float: right; */
  line-height: 32px;
}

.cvv{
    background: transparent;
    /* border: 1px solid rgb(0, 0, 0) !important; */
    border: none !important;
    color: rgb(0, 0, 0);
    border: none;
    outline: none;
    margin-right: 5px;
    border-radius: 8px;
    /* padding: 4px 10px; */
    width: 180px !important;
    font-size: 12px;
    vertical-align: middle
}

.cvv:focus{
  outline: none !important;
  color: black !important;
  text-decoration: bold !important;
}

a:hover{
  outline: none !important;
  color: rgb(102, 102, 102) !important;
  text-decoration: none !important;
  box-shadow: 24 0 10px 0 rgba(0,0,0,0.3);
}

.toolHolder{
  margin: 0 48px;
  border-bottom: 1px solid black;
  /* padding-bottom: 42px; /*15px; use if button is outside */  
  font-size: 12px;
  height: 43px
}

.linkBar{
  padding: 120px 0;
  background-color: rgb(255, 255, 255);
  font-size: 22px;
  /* margin-right: 0 !important; */
  font-family: Yeseva;


  /* width:100vw; */
  /* object-fit: cover; */
  overflow: hidden;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/joshua-ness-9iqqFZ7OuwY-unsplash.jpg") ;
  /* font-style: italic; */
  /* display: flex;
  flex-wrap: wrap; */

  /* position: -webkit-sticky;
  position: sticky;
  top: 55px;
  z-index: 200; */
}

.arr{
  flex-basis: 100%;
  text-align: left;
  padding: 0 11px;
}

.para{
  font-family: Montserrat;
  font-size: 11px;
  line-height: 15px;
  color: white;
  height: 180px;

  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  /* white-space: nowrap; */
  overflow: hidden;
}

p.para{
  margin-bottom: 0;
}

.linkBarText{
  font-size: 22px;
  line-height: 1.15;
  font-family: MontserratB;
  padding-bottom: 5px;
  color: rgb(255, 246, 194);
  min-height: 90px;
  margin-bottom: 0;
}

.newlinkBar{
  padding: 80px 100px;
  background-color: rgba(0, 0, 0, 0.788);
  display: flex;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  justify-content: space-evenly;
  font-family: Montserrat;
  font-size: 9px;
  line-height: normal;
  color: white;
}


.nav-linkbar{
  /* background-color: rgb(255, 124, 2); */
  /* border: 0.5px solid #15151D; */
  text-align: center;
  color: #15151D !important;
  /* display: flex; */
  /* justify-content:center;
  align-content:center; */
  /* flex-direction:column; */
}

a{
  color: #15151D !important;
}

.arr > .btn-outline-secondary{
  font-size: 0.65rem;
  margin-top: 12px !important;
 }

.linkbar > div > a {
  text-decoration: none;
  color: #15151D !important;
}

.littleBotton{
  padding: 0 60px 65px;
  font-size: 12px;
  font-family: Yeseva;
  display: flex;
  justify-content: center;
  /* width: 100%;
  justify-content: space-around;
  justify-items: right; */
}

.newsletter{
  padding: 60px 300px 95px;
  font-size: 12px;
  font-family: MontNor;
  /* display: flex;
  justify-content: center; */
  background-color: rgb(10, 29, 48);
}

.signupSpan{
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255) !important;
  color: #fff;
  border-radius: 20px;
  /* padding: 4px 0; */
  width: 60%;
  display: flex;
  justify-content: center;
}

#signupInputField{
  background-color: transparent !important;
  /* border: 1px solid rgb(255, 255, 255) !important; */
  color: #fff;
  border-radius: 20px;
  padding: 4px 15px;
  width: 80%;  
}

.signupButton{
  outline: none !important;
  color: rgb(252, 252, 252) !important;
  border-radius: 20px;
  padding: 5px 8px;
  /* background-color: white; */
  margin-left: 5px;
}

.signupText{
  font-family: MontNor;
  text-align: center;
  color: rgb(255, 241, 163);
  font-size: 18px;
  font-weight: medium;
  margin-top: 12px;
  margin-bottom: 12px;
}

.centerButton{
  outline: none !important;
  color: tomato !important;
  border-radius: 20px;
  padding: 5px 8px;
  background-color: white;
  font-family: MontNor;
}

.video{
  width: 100% !important;
  height: 560px;
  display: block;
}

/* .video{
  height: 300px;
  width: 100%; 
  display: block;
} */

.liner{
  /* border-top: 1px solid rgb(185, 185, 185); */
  background-color: rgb(207, 206, 206);
  height: 18px; 
}


/* .modal-content{
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  background-color: transparent;
  border: none;
  top: 70px;
} */

/* .modal{ */
  /* vertical-align: middle; */
  /* position: relative;
  display: flex !important;
  justify-content: center !important;
  align-items: center; */
/* } */

.droptext{
  margin: 10px;
}

.toolHolder span{
  vertical-align: middle;
}

.number{
  font-weight: 200;
}

.actionButtons{
  float: left;
  font-size: 12px;
}

.actionButtons > div > div{
  box-shadow: none !important;
}

.actionButtons > div > div > div{
  /* box-shadow: none !important; */
  border: none;
}

/* .actionButtons:first-child div {
  clear: right;
  float: left;
} */

.upload-btn{
  border-radius: 4px;
  border: 1px solid black;
  background-color: transparent;
}

.text-black{
  color: black;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  height: 43px
}

/* .card-grid{
  height: calc(100% - 200px);
  background: #2d9cdb;
} */


@keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translate3d(0, -1rem, 0);
    }
  }
  .bouncing-loader {
    display: flex;
    justify-content: center;
  }
  .bouncing-loader > div {
    width: 1rem;
    height: 1rem;
    margin: 3rem 0.2rem;
    background: #2d9cdb;
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  .bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  .bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
  }





  .modal-dialog{
    width: 100vw !important;
    max-width: inherit;
    opacity: 0.95;
    padding-left: 55px;
    padding-right: 55px
  }
  .modal-content{
    background: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
  }
  .modal-header{
    border-bottom: none;
    padding: 0px 30px; 
    /* Above was formerly O 15px, which was for the default 15px for modal-body, but now compensating for additional 15px from enclosing row div wrapping modal body */
  }
  /* .modal-backdrop.in{
    opacity: 0.95;
  } */
  .modal-backdrop.show{
    opacity: 0.95;
  }
  .modal-backdrop{
    background: #15151D;
  }
  /* .modal-body{
    padding: 0px 15px;
    15px comes by default, so paading unnecessary
  } */
  button.close{
    font-size: 50px;
    /* padding-right: 8%; */
    opacity: 1;
    color: #cacaca !important;
    font-weight: 100;
    outline: none !important;
  }


  @media only screen and (max-width: 600px) {
    .toolHolder{
      margin: 0 30px;
      border-bottom: 1px solid black;
      padding-bottom: 42px; /*15px; use if button is outside */ 
      font-size: 12px;
      height: 43px
    }

    .number{
      display: none;
    }

    .hideonmobile{
      display: none;
    }

    .video{
      width: 100% !important;
      height: auto;
      display: block;
    }


    .linkBar{
      padding: 0 0;
    }

    .newlinkBar{
      padding: 50px 20px;
      background-color: rgba(0, 0, 0, 0.788);
      display: flex;
      /* flex-direction: row; */
      flex-wrap: wrap;
      justify-content: space-evenly;
      font-family: Montserrat;
      font-size: 9px;
      line-height: normal;
      color: white;
    }

    .arr{
      flex-basis: 100%;
      text-align: left;
      padding: 20px 11px;
    }

    .linkBarText{
      min-height: unset;
      font-size: 1rem;
    }

    .para{
      height: initial;
      margin-bottom: 0;
    }



    .modal-dialog{
      width: 100vw !important;
      max-width: inherit;
      opacity: 0.95;
      padding-left: 0;
      padding-right: 55px;
      padding-top: 5px
    }
    .modal-content{
      background: transparent !important;
      border: 0px !important;
      box-shadow: none !important;
    }
    .modal-header{
      border-bottom: none;
      padding: 0px 0; 
    }
    .modal-body{
      border-bottom: none;
      padding-top: 0; 
    }


  }


  @media (min-width: 769px) and (max-width: 1024px) {
  
    .linkBarText{
      min-height: 75px;
      font-size: 18px;
    }

    .arr > .para{
      max-height: 224px;
      line-height: 14px;

      text-overflow: ellipsis;
      /* Required for text-overflow to do anything */
      /* white-space: nowrap; */
      overflow: hidden;
    }


    .linkBar{
      padding: 60px 0;
      /* background-color: rgb(255, 255, 255);
      font-size: 22px;
      font-family: Yeseva;

      overflow: hidden;
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/joshua-ness-9iqqFZ7OuwY-unsplash.jpg") ; */
    }

  }


  @media (min-width: 601px) and (max-width: 768px) {

    .newlinkBar {
      padding: 80px 40px;
      background-color: rgba(0, 0, 0, 0.788);
      display: flex;
      justify-content: space-evenly;
      font-family: Montserrat;
      font-size: 9px;
      line-height: normal;
      color: white;
  }
  
    .linkBarText{
      min-height: 55px;
      font-size: 14px;
    }

    .arr > .para{
      max-height: 224px;
      line-height: 14px;

      text-overflow: ellipsis;
      /* Required for text-overflow to do anything */
      /* white-space: nowrap; */
      overflow: hidden;
    }


    .linkBar{
      padding: 60px 0;
      /* background-color: rgb(255, 255, 255);
      font-size: 22px;
      font-family: Yeseva;

      overflow: hidden;
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/joshua-ness-9iqqFZ7OuwY-unsplash.jpg") ; */
    }

  }