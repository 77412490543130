.section-image{
  background-size: cover;
  overflow: hidden;
  height: 600px;
  background-color: black;
}

.section-image-two{
  background-size: cover;
  overflow: hidden;
  height: 600px;
  position: relative;
  padding: 0;
}

.section-image-two .video-section{
  background-size: cover;
  height: 300px;
  float: right;
}

.hold-row{
  display: flex;
  flex-wrap: wrap;
}

.section-image-two .text-holder{
  height: 300px;
  background-color: white;
  color: black !important;
  float: left;
}

.long-text-holder{
  height: 300px;
  background-color: #15151D;
  clear: both;

  padding-top: 3rem;

}

.long-text-holder .video-h1, .long-text-holder .video-p-text{
  color: white;
  padding-right: 14rem;
  text-align: left !important;
}


.video-section{
  background-size: cover;
  height: 300px;
}

.video-text{
  padding: 0 1rem 0 1.5rem;
  cursor: pointer;
}

.video-h1{
  color: #15151D;
  padding-top: 5rem;
  font-size: 12px;
  font-family: Montserrat;
  /* padding-left: 2rem; */
}

.video-p-text{
  color: #15151D;
  font-family: MontNor;
  font-size: 1.6rem;
  line-height: 1.2;
  text-align: left !important;
  /* padding-top: 4rem; */
}

.case{
  background: black !important;
}

.section-row{
  height: inherit;
}

.section-row-two{
  height: inherit;
}

.sections-one{
  background-color: white;
}


.blue-back{
  background: rgb(150, 213, 225);
}

.red-back{
  background: rgb(48, 71, 145);
}

.navy-back{
  background: rgb(6, 27, 43);
}

.rubicola-back{
  background: rgb(48, 71, 145);
}

.green-back{
  background: rgb(91, 195, 145);
}

.red-back{
  background: rgb(255, 135, 135);
}


.section-image img{
  max-width: 100%;
  height: auto;
}


.thumb{
  width: 100% !important;
  height: inherit;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.thumb img{
  background-size: cover;
  height: 100%;
  width: auto;
  overflow: hidden;
  background-repeat: no-repeat;
}

.no-padding{
  padding: 0 !important;
  margin: 0 !important;
}

.no-padding-only{
  padding: 0 !important;
}

.mobile-only{
  display: none
}

.desktop-only{
  display: initial
}

@media only screen and (max-width: 600px) {

  .case{
    background: white !important;
    margin: 40px 30px 30px;
  }

  .video-section{
    background-size: cover;
    height: 300px;
  }

  .section-row .section-image{
    /* background-image: url("https://res.cloudinary.com/okoyecb/image/upload/v1563979280/nikita-kachanovsky-FJFPuE1MAOM-unsplash_2x_jilvip.png");
    background-size: cover;
    overflow: hidden; */
    height: 200px;
    /* margin-bottom: 31px; */
  }

  .section-row-two .video-section{
    /* background-image: url("https://res.cloudinary.com/okoyecb/image/upload/v1563979280/nikita-kachanovsky-FJFPuE1MAOM-unsplash_2x_jilvip.png"); */
    background-size: cover;
    background-position: center;
    height: 200px;
    float: left;
  }

  .section-image-two{
    background-size: cover;
    overflow: hidden;
    height: inherit !important;
    position: relative;
    padding: 0;
  }

  .section-image-two .video-section{
    background-size: cover;
    background-position: center;
    height: 200px !important;
    float: left;
  }

  .video-text{
    background-color: #FFFFFF;
    height: initial;
    padding: 20px 0 0;
  }

  .video-h1{
    color: #15151D;
    font-size: 0.6rem;
    font-weight: bold;
    padding-top: 0;
  }

  .video-p-text{
    color: #15151D !important;
    font-size: 0.9rem;
    text-align: left !important;
  }

  .blue-back{
    background: #FFFFFF;
  }

  .red-back{
    background: #FFFFFF;
  }

  .section-image-two .text-holder{
    height: initial;
    background-color: white;
    float: right;
    padding: 0;
  }

  .text-holder{
    height: 100%;
  }


  .long-text-holder{
    height: initial;
    background-color: transparent;
    /* clear: left; */
    float: left;
    padding: 0px
  }
  
  .long-text-holder .video-h1{
    color: black;
    font-size: 0.6rem;
    /* font-family: MontNor; */
    padding-top: 0;
    margin-bottom: 6px;
  }
  
  .long-text-holder .video-text .video-p-text{
    color: black;
    padding-right: 0;
    font-size: 0.9rem;
    text-align: left !important;
  }

  .badge-holder {
    height: 100%;
    padding: 10px 0;
    background-color: #FFFFFF;
  }

  .badge-holder .video-h1{
    color: #15151D;
    font-size: 13px;
    font-weight: bold;
    padding-top: 0;
  }

  .badge-holder .video-text{
    background-color: #FFFFFF;
    padding: 10px 0 0;
  }

  .callout{
    height: 0;
  }

  .icon{
    color: red;
    background: white;
    padding: 10px;
    font-size: 20px;
    letter-spacing: 0.2px;
    border-top-right-radius: 7px;
    font-family: "icomoon" !important;
    font-style: normal;
    line-height: 1;
  }
  
  
  .section-image:hover .icon{
    color: white;
    background:  red;;
    padding: 12px;
    font-size: 22px;
    letter-spacing: 0.2px;
    border-top-right-radius: 5 px;
    font-family: "icomoon" !important;
    font-style: normal;
    line-height: 1;
    /* bottom: 10px; */
    transition: margin 0.5s cubic-bezier(0.2, 0, 0.05, 1), bottom 0.5s cubic-bezier(0.2, 0, 0.05, 1), padding-bottom 0.5s cubic-bezier(0.2, 0, 0.05, 1)
  }

  .thumb{
    width: 100% !important;
    height: inherit;
    overflow: hidden;
    float: none;
  }
  
  .thumb img{
    background-size: cover;
    height: auto;
    width: 100%;
    overflow: hidden !important;
  }

  .mobile-only{
    display: initial
  }

  .desktop-only{
    display: none
  }

}


@media (min-width: 769px) and (max-width: 1024px) {

  .long-text-holder .video-h1, .long-text-holder .video-p-text{
    padding-top: 0.5rem;
  }

  .video-h1{
    padding-top: 3rem;
  }  

  .video-p-text{
    color: #15151D;
    font-family: MontNor;
    font-size: 1.6rem;
    line-height: 1.2;
    text-align: left !important;
    padding-top: 0.5rem;
  }

}

@media (min-width: 601px) and (max-width: 768px) {

  .long-text-holder .video-h1, .long-text-holder .video-p-text{
    padding-top: 0;
  }

  .video-h1{
    padding-top: 3rem;
  }  

  .video-p-text{
    color: #15151D;
    font-family: MontNor;
    font-size: 1.3rem;
    line-height: 1.2;
    text-align: left !important;
    padding-top: 0;
  }

  .long-text-holder .video-h1, .long-text-holder .video-p-text {
    color: white;
    padding-right: 2rem;
    text-align: left !important;
  }

  .long-text-holder {
    height: 200px;
    clear: both;
    padding-top: 3rem;
  }

  .section-image {
    height: 500px;
  }

  .section-image-two {
    background-size: cover;
    overflow: hidden;
    height: 500px;
    position: relative;
    padding: 0;
  }

  .video-section {
      background-size: cover;
      height: 200px;
  }

}