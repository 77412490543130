/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */


@font-face {
  font-family: Montserrat;
  src: url(../src/assets/fonts/Montserrat-Light.ttf);
}

@font-face {
  font-family: MontserratB;
  src: url(../src/assets/fonts/Montserrat-SemiBold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: MontserratLight;
  src: url(../src/assets/fonts/Montserrat-ExtraLight.ttf);
  font-weight: lighter;
}

@font-face {
  font-family: MontNor;
  src: url(../src/assets/fonts/Montserrat-Regular.ttf);
  font-weight: lighter;
}

@font-face {
  font-family: Electrolize;
  src: url(../src/assets/fonts/Electrolize-Regular.ttf);
  font-weight: Regular;
}

@font-face {
  font-family: Yeseva;
  src: url(../src/assets/fonts/YesevaOne-Regular.ttf);
  font-weight: Regular;
}


