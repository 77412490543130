/* Footer */
.footr {
  /* justify-content: space-evenly; */
  font-size: 9px;
  padding: 0.7rem 31px;
  /* display: flex; */
  position: fixed;
  bottom: 0;
  width: 100%;
  /* height: 1.5rem;  */
  background-color: white; 
}

.footr, .footr a {
  color: #000000;
}


@media only screen and (min-width: 768px) {
  .footr {
    /* justify-content: space-evenly; */
    font-size: 9px;
    padding: 0.7rem 31px;
    /* display: flex; */
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: whitesmoke; 
    clear:both;
  }
}

/* @media only screen and (max-width: 600px) {
  .footr {
    font-size: 7px;
    padding: 0.7rem 31px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: whitesmoke; 
    clear:both;
  }

} */