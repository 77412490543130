
.errorbox{
    /* display: flex;
    justify-content: center; */
    border-bottom: 1px solid rgb(0, 0, 0);
    border-radius: 4px;
    padding: 30px;
    width: 30%
}

.errorText{
    padding: 0 60px 5px;
    font-size: 36px;
    font-family: Yeseva;
    display: flex;
    justify-content: center;
    color: darkcyan !important;
    /* width: 100%;
    justify-content: space-around;
    justify-items: right; */
  }
  
  .errorButton{
    outline: none !important;
    color: rgb(0, 0, 0) !important;
    display: flex;
    justify-content: center;
    padding: 5px 8px;
    /* background-color: white; */
  }