/* .Home {
  overflow-x: hidden;
} */

.home{
  /* height: 100vh; */
  /* width: 100vw; */
  padding-bottom: 20px;
  
  /* background: rgb(252,240,242);
  background: radial-gradient(circle, rgba(252,240,242,1) 21%, rgba(253,231,234,1) 39%, rgba(255,159,178,1) 100%); */
}


@media only screen and (max-width: 600px) {
  
  .home{
    /* height: 100vh; */
    /* width: 100vw; */
    padding-bottom: 0;
  }
}
