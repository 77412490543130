.body-row{
  padding: calc(4% + 10px) 2%;
  color: black;
  /* height: 400px; */
  /* background: rgb(255, 255, 255); */
  /* display: flex;
  flex-wrap: wrap; */

  /* width:100vw; */
  /* object-fit: cover;
  overflow: hidden;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat; */
}

.body-text{
  font-family: MontNor;
  padding-top: 2%;
  font-size: 1rem;
}

.body-text > p, .body-text > ul{
  font-family: MontNor;
  font-size: 0.85rem;
  line-height: 1.2rem;
  margin-bottom: 0.5rem;
}

.body-text > ul > li{
  list-style-type: initial;
  list-style-position: outside;
  /* text-indent: -1.3em; */
  margin-left: 1.3em;
}

.body-text > b{
  font-family: MontserratB;
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: 0.6rem;
  display: block;
}

.bold-body-text{
  font-family: MontserratB;
  /* color: rgb(255, 255, 255); */
  padding-top: 10px;
  padding-bottom: 6%;
  font-size: 1.2rem;
  line-height: 1.5rem;
  /* margin-bottom: 0.9rem; */
}

.imageChip{
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  width: 30%;
  height: 150px;
  margin-right: 15px;
}

.profileImageChip{
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 50%;
  height: 190px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {

  .trial, .second-trial, .body-text{
    font-family: MontNor;
    font-size: 0.8rem;
    line-height: 16px;
    padding-top: 0;
    text-align: left;
  }

  .body-row{
    padding-left: 15px;
    padding-right: 15px;
  }

  .bold-body-text{
    font-family: MontserratB;
    padding-top: 6%;
    margin-bottom: 0.4rem;
    text-align: left;
    font-size: 1rem;
    line-height: 20px;
  }

  .body-text{
    font-family: MontNor;
    /* color: #FFFFFF; */
    padding-top: 2%;
    margin-bottom: 0.5rem;
  }

}


@media (min-width: 601px) and (max-width: 768px) {

  .bold-body-text {
    font-family: MontserratB;
    padding-top: 10px;
    padding-bottom: 6%;
    font-size: 1.0rem;
    line-height: 1.2rem;
  }

}