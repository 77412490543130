.hero-large{
  color: white;
  font-family: MontserratB;
  font-size: 2.7rem;
  line-height: 2.9rem;
  font-weight: bold;
  /* outline:  1px solid teal;; */
}

.banner-large{
  color: white;
  font-family: MontserratB;
  font-size: 2.7rem;
  line-height: 2.9rem;
  font-weight: bold;
  /* outline:  1px solid teal;; */
}

 
.hero-p-text{
  font-family: MontNor;
  color: white;
  /* padding-top: 2%; */
  font-size: 1rem;
  line-height: 1.3rem;
  /* margin-bottom: 0.1rem; */
}

.banner-p-text{
  font-family: MontNor;
  color: rgb(241, 225, 0);
  /* padding-top: 2%; */
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 0.2rem;
}

.hero-p-text-second{
  font-family: MontserratB;
  color: rgb(255, 255, 255);
  padding-top: 10px;
  font-size: 0.9rem;
  line-height: 1.7rem;
  /* margin-bottom: 0.9rem; */
}

.contact-hero-p-text-second{
  font-family: MontserratB;
  color: rgb(255, 255, 255);
  padding-top: 10px;
  font-size: 0.9rem;
  line-height: 1.7rem;
  margin-bottom: 0.1rem !important;
}


.hero-text{
  padding-left: 60px;
  /* color: white; */
  /* padding-top: 60px; NOW SORTED WITH 'my-auto' to center vertically*/
  /* display: flex;
  flex-direction: column;
  align-items: center */
}

.contact-text{
  padding: 40px 50px;
  background-color: rgba(1, 7, 17, 0.753);
  /* background-color: rgba(3, 43, 39, 0.753); */
  /* margin-right: 20px; */
}

.contact-large{
  color: rgb(255, 246, 194);
  font-family: MontserratB;
  font-size: 1.9rem;
  line-height: 2.9rem;
  font-weight: bold;
}

.contact-p-text{
  font-family: MontNor;
  color: white;
  /* padding-top: 2%; */
  /* font-size: 1rem; */
  line-height: 1.3rem;
  /* margin-bottom: 0.1rem; */
}

.btn-contact{
  /* background-color: rgb(0, 0, 0); */
  background-color: transparent;
  color: #ABDCFC !important;
  border: none;
  padding: 0;
  font-family: MontNor;
  font-size: 0.8rem;
  /* border-radius: 4; */
  /* font-weight: 500; */
  /* margin-left: 1%; */
  }
  
  /* .hero-image{
    position: absolute;
    top: -1px;
    padding-top: 5%;
    margin-left: 17%;
    height: 29rem;
    width: 46rem;
    z-index: 1;
  } */

  .hero-row{
    padding: calc(3% + 10px) 2% 0 2%;
    height: calc(100vh - 30px); /* calc(100vh - 10px); */
    /* height: 620px; */
    background: rgb(255, 255, 255);
    display: flex;
    flex-wrap: wrap;

    /* width:100vw; */
    /* object-fit: cover; */
    overflow: hidden;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    /* background-image: url("../../assets/images/karsten-wurth--tzkyLKPvL4-unsplash.jpg") ; */
  }

  .contact-hero-row{
    padding: calc(3% + 10px) 2% 0 2%;
    height: calc(100vh - 10px);
    /* height: 620px; */
    background: rgb(255, 255, 255);
    display: flex;
    flex-wrap: wrap;

    /* width:100vw; */
    /* object-fit: cover; */
    overflow: hidden;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/milan-sunny-TqYuQt0wwHk-unsplash.jpg") ;
  }

  .banner-row{
    padding: calc(3% + 10px) 2% 0 2%;
    height: 350px;
    background: rgb(255, 255, 255);
    display: flex;
    flex-wrap: wrap;

    /* width:100vw; */
    object-fit: cover;
    overflow: hidden;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .one{
    position: absolute;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    margin: 0 40px 40px 0;
    background-color: #FF2760;
  }

  .two{
    position: absolute;
    /* top: 10px;
    left: 25px; */
    height: calc(100% - 20px);
    width: calc(100% - 40px);
    margin: 0 20px 0 20px;
    /* z-index: 2; */
    /* background-image: url("../../assets/images/Gardening-3200x2400-colored.png") ; */
    background-size: contain; 
    
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden !important;
  }

  .twoEsports{
    position: absolute;
    /* top: 10px;
    left: 25px; */
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    margin: 20px 20px 20px 20px;
    z-index: 2;
    /* background-image: url("../../assets/images/ssbp.png") ; */
    background-size: cover; 
    background-position: bottom;
    overflow: hidden !important;
  }

  .three{
    position: absolute;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    margin: 40px 0 0 40px;
    z-index: 1;
    border: 1px solid #3C3C42;
  }

 .btn-outline-secondary{
  /* background-color: rgb(0, 0, 0); */
  background-color: transparent;
  color: #ABDCFC !important;
  border: none;
  padding: 0;
  font-family: MontNor;
  font-size: 0.8rem;
  /* border-radius: 4; */
  /* font-weight: 500; */
  /* margin-left: 1%; */
  margin-top: 9px !important;
 }

 .btn-outline-secondary:hover{
  background-color: transparent;
  color: rgb(255, 255, 255) !important;
  border-radius: 4;
  /* float: right; */
  /* margin-right: 4%; */
  /* margin-top: 5px !important; */
 }

 @media only screen and (max-width: 600px) {

  .hero-row{
    height: 95vh;
    padding: 0 0 0 0;
  }

  .contact-hero-row .col-12{
    flex: initial !important;
  }

  .banner-row{
    height: 40vh;
    padding: 0 15px 0;
  }


  .contact-hero-row{
    padding: 0 0 0 0;
    height: calc(100vh - 40px);
    width: 100%;
    flex-basis: 0 !important;
  }

  .contact-text{
    padding: 40px 25px;
    margin-left: 25px;
    margin-right: 25px; 
    background-color: rgba(1, 7, 17, 0.753);
  }

  .contact-large{
    color: rgb(255, 246, 194);
    font-family: MontserratB;
    font-size: 1.4rem;
    line-height: 2.9rem;
    font-weight: bold;
  }

  .contact-p-text{
    font-family: MontNor;
    color: white;
    /* padding-top: 2%; */
    /* font-size: 1rem; */
    line-height: initial;
    /* margin-bottom: 0.1rem; */
  }

  .btn-contact{
    background-color: transparent;
    color: #ABDCFC !important;
    border: none;
    padding: 0;
    font-family: MontNor;
    font-size: 0.8rem;
    /* border-radius: 4; */
    /* font-weight: 500; */
    /* margin-bottom: 10px; */
    }

  /* .col-12 .col-md-7 .hero-image-container{
    padding-left: 0;
  } */

  .one{
    position: absolute;
    height: 0;
    width: 0;
    margin: 0;
    background-color: transparent;
  }

  .two{
    position: relative;
    /* top: 10px;
    left: 25px; */
    height: 300px;
    width: 100%;
    margin: 0;
    z-index: 2;
    /* background-image: url("../../assets/images/defender.jpg") ; */
    background-size: cover; 
    overflow: hidden !important;
  }

  .twoEsports{
    position: relative;
    /* top: 10px;
    left: 25px; */
    height: 300px;
    width: 100%;
    margin: 0;
    z-index: 2;
    /* background-image: url("../../assets/images/ssbp.png") ; */
    background-size: cover; 
    background-position: bottom;
    overflow: hidden !important;
  }

  .three{
    position: absolute;
    /* top: 20px;
    left: 40px; */
    height: 0;
    width: 0;
    margin: 0;
    z-index: 1;
    border: 0;
  }

  .hero-image-container{
    padding: 0px;
    display: none;
  }

  .trial, .second-trial{
    font-family: MontserratB;
    font-size: 2.4rem;
    padding-top: 120px;
    line-height: 37px;
    text-align: left;
  }

  .hero-large{
    font-family: MontserratB;
    font-size: 1.4rem;
    padding-top: 60px;
    line-height: 1.1;
    text-align: left;
  }

  .banner-large{
    font-family: MontserratB;
    font-size: 1.4rem;
    padding-top: 7px;
    line-height: 1.1;
    text-align: left;
  }

  .trial, .second-trial, .hero-p-text-second{
    font-family: MontNor;
    font-size: 0.8rem;
    line-height: 16px;
    padding-top: 0;
    text-align: left;
  }

  .hero-p-text-second{
    font-family: MontserratB;
    padding-top: 2%;
    margin-bottom: 0.01rem;
    text-align: left;
  }

  .trial, .second-trial, .contact-hero-p-text-second{
    font-family: MontNor;
    font-size: 0.8rem;
    line-height: 16px;
    padding-top: 0;
    text-align: left;
  }

  .contact-hero-p-text-second{
    font-family: MontserratB;
    padding-top: 6%;
    line-height: 0.3rem;
    margin-bottom: 0.1rem !important;
    text-align: left;
  }

  .hero-p-text{
    font-family: MontNor;
    font-size: 0.65rem;
    line-height: 12px;
    color: #FFFFFF;
    padding-top: 2%;
    margin-bottom: 0.05rem;
    text-align: left;
  }

  .banner-p-text{
    font-family: MontNor;
    font-size: 0.6rem;
    color: rgb(243, 227, 6);
    padding-top: 30% !important;
    margin-bottom: 0.2rem;
  }

  .trish{
    padding: 0;
  }

  /* .hero-image{
    position: relative;
    top: -1px;
    padding-top:0 !important;
    padding-right: 0 !important;
    width: 100%;
    margin-left: 0 !important;
  } */

  .hero-text{
    font-family: MontNor;
    /* text-align: center; */
    color: white;
    padding: 30px 30px;
  }

  .btn-outline-secondary{
    /* margin: 0 0 5px 0!important; */
    color: #ffffff !important;
    text-decoration: underline;
    font-family: MontNor; 
    font-size: 0.6rem; 
    font-weight: bold;  
    text-align: left !important;
    margin-left: 0 !important;
  }
 
  .btn-outline-secondary:active{
    margin-left: 0 !important;
    color: #d6d6d6;
    /* background-color: #60FDA4; */
    top: 50%;
  }

  .btn-outline-secondary:focus{
    margin-left: 0 !important;
    color: #ffffff;
    /* background-color: #60FDA4; */
    top: 50%;
  }

  .btn-outline-secondary:hover{
    margin-left: 0 !important;
    color: #151515;
    /* background-color: #60FDA4; */
    top: 50%;
  }
}


@media (min-width: 769px) and (max-width: 1024px) {
    
  .hero-row{
    padding: calc(3% + 10px) 2% 0 2%;
    height: calc(60vh - 30px); 
  }

}

@media (min-width: 601px) and (max-width: 768px) {

  .hero-row{
    padding: calc(3% + 10px) 2% 0 2%;
    height: calc(50vh - 30px); 
  }

  .hero-text {
    padding-left: 30px;
    /* color: white; */
}

  .hero-large{
    font-family: MontserratB;
    font-size: 1.3rem;
    padding-top: 60px;
    line-height: 1.1;
    text-align: left;
  }

  .banner-large {
    color: white;
    font-family: MontserratB;
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: bold;
  }

  .banner-row {
    padding: calc(3% + 35px) 2% 0 2%;
    height: 300px;
  }

  .hero-p-text-second{
    font-family: MontserratB;
    padding-top: 2%;
    margin-bottom: 0.01rem;
    text-align: left;
  }

  .hero-p-text{
    /* font-family: MontNor;
    font-size: 0.8rem;
    line-height: 15px;
    color: #FFFFFF; */
    padding-top: 0;
    margin-bottom: 0.05rem;
    text-align: left;
  }

}